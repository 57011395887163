// @ts-check
// Cynhyrchwyd y ffeil hon yn awtomatig. PEIDIWCH Â MODIWL
// This file is automatically generated. DO NOT EDIT

export function Exec(arg1) {
  return window['go']['main']['App']['Exec'](arg1);
}

export function Greet(arg1) {
  return window['go']['main']['App']['Greet'](arg1);
}
